<template>
  <ion-page>
    <ion-content style="--background: var(--ion-color-light)">
      <ion-header>
        <ion-toolbar>
          <ion-title>Add</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-card class="main-card">
        <ion-card-header>
          <router-link :to="{ path: `/member/vitals/` }" class="logo"><img src="assets/icon/back.svg" width="26" style="float: left"
          /></router-link>

          <div class="header" style="margin-top: 47px">
            <h5>
              Add new
              <br />
              vitals
            </h5>
          </div>
        </ion-card-header>

        <ion-card-content>
          <form ref="form" @submit.prevent="submit">
            <Dropdown
              v-model="form.vital_type"
              :options="types"
              optionLabel="name"
              optionValue="code"
              placeholder="Vitals"
              style="margin-bottom: 30px"
            />
            <span class="p-float-label">
              <InputText id="value" type="text" v-model="form.value" required />
              <label for="value">Your Vital</label>
            </span>

            <ion-button size="small" shape="round" type="submit" style="margin-top: 35px;">Add</ion-button>
          </form>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
} from "@ionic/vue";

export default {
  name: "Add",
  components: {
    IonContent,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      form: {
        vital_type: null,
        value: null,
        user_id: null,
      },
      types: [
        { name: "Heart Rate", code: "1" },
        { name: "Blood Pressure", code: "2" },
        { name: "Temperature", code: "3" },
        { name: "Blood Glucose", code: "4" },
        { name: "Oxygen Saturation", code: "5" },
      ],
    };
  },
  ionViewDidEnter() {
    this.form.user_id = this.user.id;
  },
  methods: {
    submit: function () {
      this.$store.dispatch("AddVital", this.form);
    },
  }
};
</script>

<style scoped>
h5 {
  font-weight: 700;
  text-align: left;
  font-size: 20px;
  line-height: 30px;
  color: var(--ion-color-primary);
}

.main-card {
  margin-top: 12px;
}

ion-button {
  float: right;
}

.p-dropdown {
  font-family: Poppins;
  height: 43.5px;
  width: 100%;
  color: #777777;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  border: 1px solid #777777;
  margin-bottom: 27px;
  text-align: left;
}
</style>